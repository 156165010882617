const resizeObserver = new ResizeObserver(() => {
    $(".tab-content").css('height', 'auto');
    $(".tab-pane").css('width', '100%');
});

window.CreateDealSampleRequest = {
    init() {
        this.validateDealSampleRequestForm();
        this.initWizard();
    },

    validateDealSampleRequestForm() {
        $('#create-sample-request-form').validate({
            onsubmit: true,
            onkeyup: false,
            onclick: false,
            rules: {
                "sample_details": {
                    required: true
                },
                "recipient_name": {
                    required: true
                },
                "deadline": {
                    required: true
                },
                "delivery_address": {
                    required: true
                },
                "supplier_ids[]": {
                    required: true
                }
            },
            messages: {
                'sample_details': 'Please provide any relevant details suppliers should know before sending the samples',
                'recipient_name': 'Please provide the recipient\'s name',
                'deadline': 'Minimum 2 weeks from today',
                'delivery_address': 'Please provide the address for delivery',
                'supplier_ids[]': 'You must select at least one supplier'
            },
            errorPlacement: (error, element) => {
                error.insertAfter(element);
            },
            onfocusout: function (element) {
                return $(element).valid();
            }
        });
    },

    initWizard() {
        $('#sample-request-smartwizard').smartWizard({
            selected: 0,
            theme: "dots",
            justified: true,
            autoAdjustHeight: true,
            enableURLhash: false,
            transition: {
                animation: 'fade',
                speed: '400',
                easing: ''
            },
            keyboardSettings: {
                keyNavigation: false
            },
            toolbarSettings: {
                toolbarButtonPosition: 'center',
                showNextButton: false,
                showPreviousButton: false
            },
            anchorSettings: {
                markDoneStep: false,
                markAllPreviousStepsAsDone: false,
                removeDoneStepOnNavigateBack: true
            }
        });

        $("#sample-request-smartwizard").on("leaveStep", function (e, anchorObject, currentStepIndex, nextStepIndex, stepDirection) {
            if (stepDirection != "forward") {
                return true;
            }

            let valid = [];

            if (currentStepIndex == 0) {
                valid.push($("[name='sample_title']").valid());
                valid.push($("textarea[name='sample_details']").valid());
                valid.push($("input[name='recipient_name']").valid());
                valid.push($("input[name='delivery_address']").valid());
                valid.push($("input[name='deadline']").valid());

                if (!valid.every(Boolean)) {
                    return false
                }
            } else if (currentStepIndex == 1) {
                valid.push($("[name='supplier_team_ids[]']").valid());
            }

            return !valid.includes(false);
        });

        if (document.querySelector('#step-0') != null) {
            resizeObserver.observe(document.querySelector('#step-0'));
            resizeObserver.observe(document.querySelector('#step-1'));
            resizeObserver.observe(document.querySelector('#step-2'));
        }
    }
};
