import Tagify from '@yaireo/tagify'
window.Tagify = {
  init() {
    this.Tagify();
    this.submitForm();
  },

  getTags() {
    let tags = []
    $.ajax({
      type: "GET",
      async: false,
      url: '/all_tags.json',
      success: function(data) {
        tags = data
      },
    });
    return tags;
  },

  Tagify() {
    let tags = []
    tags = this.getTags();
    var inputElm = document.querySelector('input[name=company_tags]'), whitelist = tags
    // initialize Tagify on the above input node reference
    var tagify = new Tagify(inputElm, {
      enforceWhitelist: false,

      // make an array from the initial input value
      whitelist: inputElm.value.trim().split(/\s*,\s*/)
    })

    // Chainable event listeners
    tagify.on('add', onAddTag)
      .on('remove', onRemoveTag)
      .on('input', onInput)
      // .on('edit', onTagEdit)
      // .on('invalid', onInvalidTag)
      // .on('click', onTagClick)
      // .on('focus', onTagifyFocusBlur)
      // .on('blur', onTagifyFocusBlur)
      .on('dropdown:hide dropdown:show', e => console.log(e.type))
      // .on('dropdown:select', onDropdownSelect)

    var mockAjax = (function mockAjax() {
      var timeout;
      return function(duration) {
        clearTimeout(timeout); // abort last request
        return new Promise(function(resolve, reject) {
          timeout = setTimeout(resolve, duration || 700, whitelist)
        })
      }
    })()

    // tag added callback
    function onAddTag(e) {
      tagify.off('add', onAddTag) // exmaple of removing a custom Tagify event
    }

    // tag remvoed callback
    function onRemoveTag(e) {
      console.log("onRemoveTag:", e.detail, "tagify instance value:", tagify.value)
    }

    // on character(s) added/removed (user is typing/deleting)
    function onInput(e) {
      tagify.settings.whitelist.length = 0; // reset current whitelist
      tagify.loading(true).dropdown.hide.call(tagify) // show the loader animation

      // get new whitelist from a delayed mocked request (Promise)
      mockAjax()
        .then(function(result) {
          // replace tagify "whitelist" array values with new values
          // and add back the ones already choses as Tags
          tagify.settings.whitelist.push(...result, ...tagify.value)

          // render the suggestions dropdown.
          tagify.loading(false).dropdown.show.call(tagify, e.detail.value);
        })
    }
  },

  submitForm() {
    let company_uuid = window.location.pathname.split('/')[3]
    $('#submit').on('click', function() {
      let tags = []
      if($('#tags').val().length > 0)
        tags = JSON.parse($('#tags').val()).map( x => x.value)
      $.ajax({
        type: "POST",
        url: '/save-tags',
        data: {
          uuid: company_uuid,
          tags: tags
        },
        success: function() {},
      });
    })

    $('#submit1').on('click', function() {
      let tags = []
      if($('#tags').val().length > 0)
        tags = JSON.parse($('#tags').val()).map( x => x.value)
      $.ajax({
        type: "POST",
        url: '/admin/buyers/save_tags',
        data: {
          uuid: company_uuid,
          tags: tags
        },
        success: function() {},
      });
    })
  },

  get_users(){
    let user_emails = []
      $.ajax({
        type: "GET",
        async: false,
        url: 'users/current_company_user_emails.json',
        success: function(data) {
          user_emails = data
        },
      });
    return user_emails;
  },

  tagify_team(inputElm) {
    var whitelist = this.getTags()
    var tagify = new Tagify(inputElm, {
      enforceWhitelist: false,
      whitelist: inputElm.value.trim().split(/\s*,\s*/),
      originalInputValueFormat: valuesArr => valuesArr.map(item => item.value)
    })

    var observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if(mutation.attributeName === "disabled") {
          tagify.setDisabled(inputElm.disabled);
        }
      });    
    });
    observer.observe(inputElm, { attributes: true });

    // Chainable event listeners
    tagify.on('add', onAddTag)
      .on('input', onInput)

    var mockAjax = (function mockAjax() {
      var timeout;
      return function(duration) {
        clearTimeout(timeout); // abort last request
        return new Promise(function(resolve, reject) {
          timeout = setTimeout(resolve, duration || 700, whitelist)
        })
      }
    })()

    // tag added callback
    function onAddTag(e) {
      tagify.off('add', onAddTag) // exmaple of removing a custom Tagify event
    }

    // on character(s) added/removed (user is typing/deleting)
    function onInput(e) {
      tagify.settings.whitelist.length = 0; // reset current whitelist
      tagify.loading(true).dropdown.hide.call(tagify) // show the loader animation

      // get new whitelist from a delayed mocked request (Promise)
      mockAjax()
        .then(function(result) {
          // replace tagify "whitelist" array values with new values
          // and add back the ones already choses as Tags
          tagify.settings.whitelist.push(...result, ...tagify.value)

          // render the suggestions dropdown.
          tagify.loading(false).dropdown.show.call(tagify, e.detail.value);
        })
    }
  },

  tagify_user() {
    var user_emails
    user_emails = this.get_users();
    var inputElm = document.querySelector('input[name=user_tags]')
    var whitelist
    var full_name = []
    for(var i=0; i < user_emails.length; i++)
    {
      var obj = {}
      obj.id = user_emails[i][0]
      obj.value = user_emails[i][1] + ' ' + user_emails[i][2]
      full_name.push(obj)
    }
    whitelist = full_name
    var tagify = new Tagify(inputElm, {
      enforceWhitelist: true,
      whitelist: whitelist,
      dropdown: {
        enabled: 0,
        closeOnSelect: false
      }
    })

    tagify.on('add', onAddTag)
      .on('remove', onRemoveTag)
      .on('input', onInput)
      .on('dropdown:hide dropdown:show', e => console.log(e.type))
      var mockAjax = (function mockAjax() {
      var timeout;
      return function(duration) {
        clearTimeout(timeout);
        return new Promise(function(resolve, reject) {
          timeout = setTimeout(resolve, duration || 700, whitelist)
        })
      }
      })()

      function onAddTag(e) {
        tagify.off('add', onAddTag)
      }

      function onRemoveTag(e) {
        console.log("onRemoveTag:", e.detail, "tagify instance value:", tagify.value)
      }

      function onInput(e) {
        tagify.settings.whitelist.length = 0;
        tagify.loading(true).dropdown.hide.call(tagify)
        mockAjax()
          .then(function(result) {
            tagify.settings.whitelist.push(...result, ...tagify.value)
            tagify.loading(false).dropdown.show.call(tagify, e.detail.value);
          })
      }
  },
  tagify_member() {
    var user_emails
    user_emails = this.get_users();
    var inputElm = document.querySelector('input[name=member_tags]')
    var whitelist
    var full_name = []
    for(var i=0; i < user_emails.length; i++)
    {
      var obj = {}
      obj.id = user_emails[i][0]
      obj.value = user_emails[i][1] + ' ' + user_emails[i][2]
      full_name.push(obj)
    }
    whitelist = full_name
    var tagify = new Tagify(inputElm, {
      enforceWhitelist: true,
      whitelist: whitelist,
       dropdown: {
        enabled: 0,
        closeOnSelect: false
      }
    })

    tagify.on('add', onAddTag)
      .on('remove', onRemoveTag)
      .on('input', onInput)
      .on('dropdown:hide dropdown:show', e => console.log(e.type))
      var mockAjax = (function mockAjax() {
      var timeout;
      return function(duration) {
        clearTimeout(timeout);
        return new Promise(function(resolve, reject) {
          timeout = setTimeout(resolve, duration || 700, whitelist)
        })
      }
      })()

      function onAddTag(e) {
        tagify.off('add', onAddTag)
      }

      function onRemoveTag(e) {
        console.log("onRemoveTag:", e.detail, "tagify instance value:", tagify.value)
      }

      function onInput(e) {
        tagify.settings.whitelist.length = 0;
        tagify.loading(true).dropdown.hide.call(tagify)
        mockAjax()
          .then(function(result) {
            tagify.settings.whitelist.push(...result, ...tagify.value)
            tagify.loading(false).dropdown.show.call(tagify, e.detail.value);
          })
      }  
  },

  get_companies(){
    let company_names = []
      $.ajax({
        type: "GET",
        async: false,
        url: 'companies/buyer_companies.json',
        success: function(data) {
          company_names = data
        },
      });
    return company_names;
  },

  tagify_company() {
    var company_names
    company_names = this.get_companies();
    var inputElm = document.querySelector('input[name=company_tags]')
    var whitelist
    var full_name = []
    for(var i=0; i < company_names.length; i++)
    {
      var obj = {}
      obj.id = company_names[i][0]
      obj.value = company_names[i][1]
      full_name.push(obj)
    }
    whitelist = full_name
    var tagify = new Tagify(inputElm, {
      enforceWhitelist: true,
      whitelist: whitelist,
      dropdown: {
        enabled: 0,
        closeOnSelect: false
      }
    })

    tagify.on('add', onAddTag)
      .on('remove', onRemoveTag)
      .on('input', onInput)
      .on('dropdown:hide dropdown:show', e => console.log(e.type))
      var mockAjax = (function mockAjax() {
      var timeout;
      return function(duration) {
        clearTimeout(timeout);
        return new Promise(function(resolve, reject) {
          timeout = setTimeout(resolve, duration || 700, whitelist)
        })
      }
      })()

      function onAddTag(e) {
        tagify.off('add', onAddTag)
      }

      function onRemoveTag(e) {
        console.log("onRemoveTag:", e.detail, "tagify instance value:", tagify.value)
      }

      function onInput(e) {
        tagify.settings.whitelist.length = 0;
        tagify.loading(true).dropdown.hide.call(tagify)
        mockAjax()
          .then(function(result) {
            tagify.settings.whitelist.push(...result, ...tagify.value)
            tagify.loading(false).dropdown.show.call(tagify, e.detail.value);
          })
      }
  },

  init_team(inputElm) {
    this.tagify_team(inputElm)
  },

  init_user() {
    this.tagify_user()
  },

  init_company() {
    this.tagify_company()
  },
  init_members() {
    this.tagify_member()
    const selected_emails = [];
    $('#submit_group_members').click(function() {
      var channel_id = document.getElementById("member_id").value;
      var participants = JSON.parse(document.getElementById('member_tag_id').value)
      for (var j = 0; j < participants.length; j++) {
        selected_emails.push(participants[j].id)
      }
      $.ajax({
          url: `/channels/add_participants_to_channel`,
          type: 'POST',
          data: { 'selected_users': selected_emails, 'member_channel_id': channel_id },
          success: (data) => {
            window.top.Meetings.reloadHtmlPanel();
          },
          failure: (error) => {
            alert(error)
          }
        });
    });
  },
}
