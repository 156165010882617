window.WidgetDeal = {
    init() {
      let stackedCard = new stackedCards({
        selector: '.stacked-cards',
        layout: "slide",
        transformOrigin: "center",
      });
      stackedCard.init();
  
      const cardElements = $(".stacked-cards li")
      const cardElementLenght = cardElements.length
      let i = 1
      setInterval(() => {
        cardElements[i].click()
        if (i == cardElementLenght - 1) {
          i = 0
        }
        else { i = i + 1 }
      }, 5000);
  
      $('.text-ellipsis').bind('mouseenter', function() {
        var $this = $(this);
  
        if(this.offsetWidth < this.scrollWidth && !$this.attr('title')){
          $this.attr('title', $this.text());
        }
      });
    }
  }
  