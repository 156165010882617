import DailyIframe from '@daily-co/daily-js';

window.DailyVideo = {
  createFrame() {
      let callFrame = DailyIframe.createFrame({
        showLeaveButton: true,
        showFullscreenButton: true
      });
      callFrame.iframe().style.visibility = 'hidden';
      window.callFrame = callFrame;
  },

  getRoomURLAndJoin() {
    const self = this;
    let selectedConversationId = TalkMessaging.currentConversationID();
    $.ajax({
      url: `/video_calls/room`,
      type: 'GET',
      data: {
        conversation_id: selectedConversationId
      },
      success: (data) => {
        if (data) {
          self.joinRoom(
            data['roomURL'],
            data['token']
          );
        }
      },
      failure: (error) => {
        alert(error)
      }
    });
  },

  startCall() {
    if (!authorizeVideoCalling) {
      $('#not-authorized-video-calling-modal').modal('show');
      return;
    }

    if (callFrame.iframe().style.visibility == 'visible') {
      return;
    }

    callFrame.iframe().style.visibility = 'visible';
    Intercom('update', {"hide_default_launcher": true});
    this.getRoomURLAndJoin();
  },

  joinRoom(url, token) {
    callFrame.join({
      url: url,
      token: token
    });

    callFrame.on(
      'left-meeting', (event) => {
        callFrame.iframe().style.visibility = 'hidden';
        Intercom('update', {"hide_default_launcher": false});
      }
    ).on(
      'joined-meeting', (event) => {
        callFrame.iframe().style.visibility = 'visible';
        Intercom('update', {"hide_default_launcher": true});
      }
    );
  },
};
