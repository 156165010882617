window.Meetings = {
  init() {
    this.setCompanyTimezone();
    this.setClickHandler();
  },

  reloadHtmlPanel() {
    if (window.htmlPanel) {
      window.htmlPanel.window.location.reload();
    }
    else {
      location.reload();
    }
  },

  setCompanyTimezone() {
    
    $('#time_zone').val(jstz.determine().name());
    $('#interpreter_confirmed_modal').on('hidden.bs.modal', function () {
      window.top.Meetings.reloadHtmlPanel();
    });
    $('#btnInterpreterCancel').click(function() {
      window.top.Meetings.reloadHtmlPanel();
    });
  },

  setClickHandler() {
    const self = this;
    $(document).ready(function() {
      $('#btnInterpreterAgree').click(function(e) {
        let meetingID = $(e.target).data('meeting-id');
        self.requestInterpreter(meetingID);
      });

      $('#btnGenerateCopyDailyLink').click(function(e) {
        let meetingID = $(e.target).data('meeting-id');
        self.generateAndCopyMeetingLink(meetingID);
      });

      self.attachAddEventDynamicEvents();
    });
  },

  proposeMeeting() {
    if (authorizeMeeting) {
      $('#propose_meeting_modal').modal('show');
    } else {
      $('#not-authorized-meetings-modal').modal('show');
    }
  },

  revokeMeeting(meetingID) {
    $.ajax({
      url: `/video_meetings/` + meetingID + `/revoke`,
      type: 'POST',
      success: (data) => {
        window.top.Meetings.reloadHtmlPanel();
      },
      failure: (error) => {
        alert(error)
      }
    });
  },

  acceptMeeting(meetingID) {
    $.ajax({
      url: `/video_meetings/` + meetingID + `/accept`,
      type: 'POST',
      success: (data) => {
        window.top.Meetings.reloadHtmlPanel();
      },
      failure: (error) => {
        alert(error)
      }
    });
  },

  rejectMeeting(meetingID) {
    $.ajax({
      url: `/video_meetings/` + meetingID + `/reject`,
      type: 'POST',
      success: (data) => {
        window.top.Meetings.reloadHtmlPanel();
      },
      failure: (error) => {
        alert(error)
      }
    });
  },

  cancelMeeting(meetingID) {
    $.ajax({
      url: `/video_meetings/` + meetingID + `/cancel`,
      type: 'POST',
      success: (data) => {
        window.top.Meetings.reloadHtmlPanel();
      },
      failure: (error) => {
        alert(error)
      }
    });
  },

  requestInterpreter(meetingID) {
    $.ajax({
      url: `/video_meetings/` + meetingID + `/request_interpreter`,
      type: 'POST',
      success: (data) => {
        $('#request_interpreter_modal').modal('hide');
        window.location.reload();
      },
      failure: (error) => {
        alert(error)
      }
    });
  },

  copyToClipboard(text) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(text).select();
    document.execCommand("copy");
    $temp.remove();
  },

  generateAndCopyMeetingLink(meetingID) {
    const self = this;
    $.ajax({
      url: `/video_meetings/` + meetingID + `/generate_link`,
      type: 'POST',
      success: (data) => {
        let url = data['roomURL'] + '?t=' + data['token'];
        self.copyToClipboard(url);
        swal('Link copied!');
      },
      failure: (error) => {
        alert(error)
      }
    });
  },

  addMeetingToCalendar(start, tz, title, description, location) {
    let data = '<div title="Add to Calendar" class="addeventatc"> \
    Add to Calendar\
    <span class="start">' + start + '</span>\
    <span class="timezone">' + tz + '</span>\
    <span class="title">' + title + '</span>\
    <span class="description">' + description + '</span>\
    <span class="location">' + location + '</span>\
</div>\
    ';
    $('#add_to_calendar_modal').find('.modal-body').html(data);
    $('#add_to_calendar_modal').modal('show');
    addeventatc.refresh();
    this.attachAddEventDynamicEvents();
    window.addeventasync();
    $('#addeventatc1').click();
  },

  attachAddEventDynamicEvents() {
    window.addeventasync = function() {
      addeventatc.register('button-dropdown-click', function(obj) {
        $('#add_to_calendar_modal').modal('hide');
      });
    }
  }
};
