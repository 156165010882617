window.Conversations = {
    setupDashboardEvents() {
        this.handleLoadMoreConversations('.show-more-deal-conversations', 'deal', 'deal', false);
        this.handleLoadMoreConversations('.show-more-team-conversations', 'team', 'team', false);
    },

    setupSidebarEvents() {
        this.handleToggle('request-message-toggle', 'request-message-list');
        this.handleToggle('general-message-toggle', 'general-message-list');
        this.handleToggle('closed-deals-toggle', 'closed-request-message-list');
        this.handleToggle('archived-team-message-toggle', 'archived-general-message-list');
        this.handleEachDealMessageToggle();
        this.handleLoadMoreConversations('.show-more-active-deals', 'deal', 'active_deal', true);
        this.handleLoadMoreConversations('.show-more-closed-deals', 'deal', 'closed_deal', true);
        this.handleLoadMoreConversations('.show-more-active-team-messages', 'team', 'team', true);
        this.handleLoadMoreConversations('.show-more-archived-team-messages', 'team', 'archived_team', true);
    },

    setupChatDetailsEvents() {
        this.handleShowMore('.show-more-participants', '.participants-list');
        this.handleShowMore('.show-more-attachments', '.attachments-list');
    },

    setupShowAllEvents() {
        this.handleLoadMoreConversations('.show-more-filtered-deal-conversations', 'deal', 'filtered_deal', false);
        this.handleLoadMoreConversations('.show-more-filtered-team-conversations', 'team', 'filtered_team', false);
    },

    handleToggle(toggleElementId, elementToToggleId) {
        document.addEventListener('DOMContentLoaded', () => {
            const toggle = document.getElementById(toggleElementId);
            const list = document.getElementById(elementToToggleId);

            if (toggle) {
                const openImageSrc = toggle.getAttribute('data-open-image');
                const closedImageSrc = toggle.getAttribute('data-closed-image');
                toggle.addEventListener('click', () => {
                    const isHidden = list.style.display === 'none';
                    list.style.display = isHidden ? 'block' : 'none';
                    toggle.src = isHidden ? openImageSrc : closedImageSrc;
                });
            }
        });
    },

    handleEachDealMessageToggle() {
        document.addEventListener('click', function (e) {
            if (e.target && e.target.id.startsWith('deal-message-toggle-')) {
                const id = e.target.id.split('-').pop();
                const dealMessageList = document.getElementById(`deal-message-list-${id}`);

                if (dealMessageList) {
                    const openImageSrc = e.target.getAttribute('data-open-image');
                    const closedImageSrc = e.target.getAttribute('data-closed-image');
                    const isHidden = dealMessageList.style.display === 'none';
                    dealMessageList.style.display = isHidden ? 'block' : 'none';
                    e.target.src = isHidden ? openImageSrc : closedImageSrc;
                }
            }
        });
    },

    handleShowMore(showMoreElementId, elementToShowMoreId) {
        document.addEventListener('DOMContentLoaded', () => {
            const showMoreButton = document.querySelector(showMoreElementId);
            const conversationList = document.querySelector(elementToShowMoreId);

            if (conversationList) {
                Array.from(conversationList.children).forEach((conversation, index) => {
                    if (index > 2) {
                        conversation.style.display = 'none';
                    } else {
                        conversation.style.display = 'block';
                    }
                });

                const totalConversations = parseInt(conversationList.dataset.totalConversations, 10);
                let currentVisibleConversations = Array.from(conversationList.children).filter(e => window.getComputedStyle(e).display === 'block').length;

                if (showMoreButton) {
                    showMoreButton.addEventListener('click', () => {
                        const conversationsToShow = Array.from(conversationList.children)
                            .slice(currentVisibleConversations, currentVisibleConversations + 2);

                        conversationsToShow.forEach(conversation => {
                            conversation.style.display = 'block';
                        });

                        currentVisibleConversations += conversationsToShow.length;
                        if (currentVisibleConversations >= totalConversations) {
                            showMoreButton.style.display = 'none';
                        }
                    });
                }
            }
        });
    },

    handleLoadMoreConversations(loadMoreElementId, conversationType, page, sidebar) {
        $(document).on('click', loadMoreElementId, function (e) {
            e.preventDefault();

            let pageType = `${page}_page`;
            var nextPage = $(this).data('next-page') || 2;
            let enterprise_team_id = $('#enterprise_team_id').val();
            let filter_team_id = $('#filter_team_id').val();
            let data = {
                [`${pageType}`]: nextPage,
                conversation_type: conversationType,
                enterprise_team_id: enterprise_team_id,
                filter_team_id: filter_team_id
            }
            if (sidebar) {
                data.sidebar = sidebar;
            }

            $.ajax({
                url: '/conversations/load_more_conversations',
                type: 'GET',
                data: data,
                dataType: 'script'
            });
        });
    },

    setupConversationSearch() {
        var typingTimeout;
        var typingDelay = 100;

        $('.searchvalue').on('keyup', function () {
            clearTimeout(typingTimeout);
            typingTimeout = setTimeout(handleDoneTyping, typingDelay);
        });

        $('.searchvalue').on('keydown', function () {
            clearTimeout(typingTimeout);
        });

        function handleDoneTyping() {
            var inputSearch = document.getElementById('conversationSearchInput');
            var query = inputSearch.value.trim().toUpperCase();
            if (query.length === 0) {
                $('#conversation-search-results').html("").hide();
            } else {
                $('#conversation-search-results').html("");
                Conversations.searchConversations(query);
            }
        }

        $(document).on('click', function (event) {
            if (!$(event.target).closest('.search-container').length) {
                $('#conversation-search-results').hide();
            }
        });
    },

    searchConversations(query) {
        $.ajax({
            url: `/search_conversations?query=${query}`,
            dataType: 'json',
            async: false,
            success: function (data) {
                Conversations.populateResults(data);
            }
        });
    },

    populateResults(results) {
        var wrapper = document.getElementById('conversation-search-results');

        if (results.length > 0) {
            var dealResultsWrapper = document.createElement('div');
            var dealResultsTitle = document.createElement('p');
            dealResultsTitle.innerHTML += 'Request Messages';
            dealResultsWrapper.appendChild(dealResultsTitle);

            var teamResultsWrapper = document.createElement('div');
            var teamResultsTitle = document.createElement('p');
            teamResultsTitle.innerHTML += 'General Messages';
            teamResultsWrapper.appendChild(teamResultsTitle);

            var keys_array = Object.keys(results);
            for (var i = 0; i < keys_array.length; i++) {
                const result = results[keys_array[i]];
                var resultRow = document.createElement('a');
                resultRow.href = `/conversations/${result.id}`;
                resultRow.className = 'entry panel row';
                resultRow.style.display = 'flex';
                resultRow.style.alignItems = 'center';

                const titleSpan = document.createElement('span');
                titleSpan.textContent = result.title;
                titleSpan.style.flex = 11;
                if (result.participant_full_names && result.participant_full_names.length > 0) {
                    titleSpan.textContent += ' - ' + result.participant_full_names.join(', ');
                }
                resultRow.appendChild(titleSpan);

                if (result.unread) {
                    const unreadBadge = document.createElement('div');
                    unreadBadge.className = 'unread-message-badge';
                    unreadBadge.style.backgroundColor = '#31B4ED';
                    resultRow.appendChild(unreadBadge);
                }

                if (result.type === 'deal') {
                    dealResultsWrapper.appendChild(resultRow);
                } else if (result.type === 'team' || result.type === 'support') {
                    teamResultsWrapper.appendChild(resultRow);
                }
            }

            if (dealResultsWrapper.children.length > 1) {
                wrapper.appendChild(dealResultsWrapper);
            }

            if (teamResultsWrapper.children.length > 1) {
                wrapper.appendChild(teamResultsWrapper);
            }
        } else {
            var noResultsWrapper = document.createElement('div');
            var noResultsTitle = document.createElement('p');
            noResultsTitle.innerHTML += 'No results found.';
            noResultsWrapper.appendChild(noResultsTitle);
            wrapper.appendChild(noResultsWrapper);
        }

        if (wrapper.children.length > 0) {
            $('#conversation-search-results').show();
        } else {
            $('#conversation-search-results').hide();
        }
    },
    enterpriseCompanyChange() {
        $(".enterprise_bar_dropdown").change(function (e) {
            let selectedId = $(this).find("option:selected").val();
            window.location.href = '/conversations?enterprise_team_id=' + selectedId;
        });
    },
}
