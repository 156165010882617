import '@popperjs/core';
import { PHONE_EMAIL } from './constants';

const AUTH_TOKEN=$('meta[name="csrf-token"]').attr('content');
const step0ResizeObserver = new ResizeObserver(() => {
  $(".tab-content").css('height', 'auto');
});
const step3ResizeObserver = new ResizeObserver(() => {
  $(".tab-content").css('height', 'auto');
});

const step2ResizeObserver = new ResizeObserver(() => {
  $(".tab-content").css('height', 'auto');
});

const step4ResizeObserver = new ResizeObserver(() => {
  $(".tab-content").css('height', 'auto');
});

let uuid = ''
let path = window.location.pathname
const wheretoplace = () => {
  var width = window.innerWidth;
  if (width < 992) return 'bottom';
  return 'right';
}
window.Deal = {

  init() {
    this.initDropZone();
    this.initLightSlider();
    this.initForm();
    this.initWizard();
    this.initPopover();
    this.validateForm();
    this.handleRating();
    this.toggleRating();
    this.ratingCheckboxOnLoad();
    this.handleCompanyAgeRange();
    this.handleOnClickRemoveAttachment();
    this.changeDealStatus()
    this.addCustomCSS()
  },

  initDropZone() {
    const self = this
    const submitBtn = $('.submit-deal-btn')
    $(".dropzone").not(".dz-clickable").each((index, element) => {
      $(element).dropzone({
        url: "/deals/upload_deal_attachment",
        previewsContainer: $(element).siblings('.drop-zone-preview')[0],
        previewTemplate: document.querySelector('.drop-zone-custom').innerHTML,
        uploadMultiple: false,
        maxFiles: 5,
        maxFileSize: 10,
        thumbnailWidth: 75,
        thumbnailHeight: 75,
        acceptedFiles: "image/*,video/*,application/pdf",
        init: function() {
          this.on("error", function(file) {
            this.removeFile(file);
            $('.dropzone').after(
              '<div class="alert alert-danger alert-dismissable" id="rr-success-alert">'+
                  '<button type="button" class="close" ' +
                    'data-dismiss="alert" aria-hidden="true">' +
                      '&times;' +
                  '</button>' +
                  'This file format is not supported' +
              '</div>'
            );
            $("#rr-success-alert").fadeTo(5000, 500).slideUp(500, function() {
              $("#rr-success-alert").slideUp(500);
            });
            setTimeout(function() {
              $("#rr-success-alert").remove();
            }, 6000);
          });
        },
        params:{
          'authenticity_token':  AUTH_TOKEN
        },
        success: (file, response) => {
          if (response.err_msg == '' || response.err_msg == undefined) {
            const dealAttachmentsElement = $("#deal-attachments");
            $(file.previewElement).find('.remove-logo').attr('data-attachment-id', response.id);
            dealAttachmentsElement.append(`<input type='hidden' value='${response.id}' name='deal[deal_attachment_ids][]'>`);
            self.handleOnClickRemoveAttachment();
          } else {
            alert(response.err_msg);
            file.previewElement.remove();
          }
          submitBtn.removeAttr('disabled');
        },
        sending: () => {
          submitBtn.attr('disabled','disabled')
        }
      });
    });
  },

  initForm() {
    $("#deal_frequency_type").on('change', function() {
      if ($(this).val() == "once" || $(this).val() == "for_discussion") {
        $("#frequency_interval_container").css("display","none");
      }
      else {
        $("#frequency_interval_container").css("display","block");
      }
    })
  },

  initLightSlider() {
    $(document).ready(function() {
      $("#light-slider").lightSlider({
        gallery:true,
            item:1,
            thumbItem:5,
            slideMargin: 0,
            speed:500,
            pause: 5000,
            pauseOnHover: true,
            auto:true,
            loop:true,
            prevHtml: '&lt;',
            nextHtml: '&gt;',
            onSliderLoad: function() {
                $('#light-slider').removeClass('cS-hidden');
            }
      }
    );
  });
  },

  initPopover() {
    $('#deal_name').popover({
      title : "Request Name",
      content: "Give your request a descriptive and eye-catching name.",
      trigger: "focus",
      class: "company-private-info",
      placement: wheretoplace,
      template: '<div class="popover company-private-popover"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    });

    $('#public_deal').popover({
      content:  "All Kwayga members can see it was you who posted this request.",
      trigger: "hover",
      placement: 'top',
      template: '<div class="popover company-private-popover"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    });

    $('#private_deal').popover({
      content: "No one can see who posted this request.",
      trigger: "hover",
      placement: 'top',
      template: '<div class="popover company-private-popover"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    });

    $('#connected_deal').popover({
      content: " Only your Kwayga Connections can see it was you who posted this request.",
      trigger: "hover",
      class: "company-private-info",
      placement: 'top',
      template: '<div class="popover company-private-popover"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    });

    $('#deal_stage_deadline').popover({
      title: "Request Deadline",
      content: "Specify a date time by which this request will be closed.",
      trigger: "focus",
      class: "company-private-info",
      placeholder: wheretoplace,
      template: '<div class="popover company-private-popover"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    });
    $('.note-editable').popover({
      title : "Request Description",
      content: "Tell us about your request.",
      trigger: "focus",
      class: "company-private-info",
      placement: wheretoplace,
      template: '<div class="popover company-private-popover"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    });
    $('#deal_quantity').popover({
      title : "Request Quantity",
      content: "Sizing your request helps interested suppliers understand if they are a good match for you.",
      trigger: "focus",
      class: "company-private-info",
      placement: wheretoplace,
      template: '<div class="popover company-private-popover"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    });
    $('#deal_frequency_type').popover({
      title : "Request Frequency",
      content: "Is this a one off request? Or is it recurring? Let suppliers know. ",
      trigger: "focus",
      class: "company-private-info",
      placement: wheretoplace,
      template: '<div class="popover company-private-popover"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    });
    $('#deal_budget').popover({
      title : "Request Budget",
      content: "Have you a budget for this request? Setting a budget helps interested suppliers understand if they are a good match for you.",
      trigger: "focus",
      class: "company-private-info",
      placement: wheretoplace,
      template: '<div class="popover company-private-popover"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
    });
  },

  initRevenueSlider() {
    var valMap = [1,3,5,15,30,60];
    $(function(){
      $("#revenue-range-slider").slider({
        range:true,
        min: 0,
        max: 10,
        values: [ 0, 10],
        slide: function( event, ui ) {
          var display_from = "€0";
          var display_to = "€500M +";
          var from = "0";
          var to = "-1";
          display_from = window.Deal.parseRevenueSliderDisplayValue(ui.values[0]);
          display_to = window.Deal.parseRevenueSliderDisplayValue(ui.values[1]);
          from = window.Deal.parseRevenueSliderValue(ui.values[0]);
          to = window.Deal.parseRevenueSliderValue(ui.values[1]);

          if (from == -1 && to == -1){
            $("#price").val(display_to);
          }
          else {
            $("#price").val(display_from + " - " + display_to);
          }

          $("#deal_supplier_turnover_min").val(from);
          $("#deal_supplier_turnover_max").val(to);
        }
      });

      var from_val = $("#deal_supplier_turnover_min").val();
      var to_val = $("#deal_supplier_turnover_max").val();

      //on a new deal the to_val will be 0, but for UX purposes we should set it to max
      to_val = to_val == 0 ? -1 : to_val;

      var from = window.Deal.parseRevenueValueToSliderValue(from_val);
      var to = window.Deal.parseRevenueValueToSliderValue(to_val);

      $("#revenue-range-slider").slider("values", [from, to]);

      var display_from = window.Deal.parseRevenueSliderDisplayValue($("#revenue-range-slider").slider("values", 0));
      var display_to = window.Deal.parseRevenueSliderDisplayValue($("#revenue-range-slider").slider("values", 1));

      $("#price").val(display_from + " - " + display_to);
    });
  },

  initWizard() {
    $('#smartwizard').smartWizard({
      selected: 0,
      theme: "dots",
      justified: true,
      autoAdjustHeight: true, // Automatically adjust content height
      enableURLhash: false,
      lang: { // Language variables for button
        next: 'Next >>',
        previous: '<< Previous'
      },
      transition: {
        animation: 'slide-horizontal', // Effect on navigation, none/fade/slide-horizontal/slide-vertical/slide-swing
        speed: '400', // Transion animation speed
        easing:'' // Transition animation easing. Not supported without a jQuery easing plugin
      },
      keyboardSettings: {
        keyNavigation: false
      },
      toolbarSettings: {
        toolbarButtonPosition: 'center'
      }
    });
    $("#smartwizard").on("leaveStep", function(e, anchorObject, currentStepIndex, nextStepIndex, stepDirection) {
      $('html,body').animate({scrollTop: $("div#smartwizard").offset().top - 100},'slow');

      if (stepDirection != "forward") { return true; }

      let valid = [];

      if (currentStepIndex == 0) {
          valid.push($("[name='deal[team_id]']").valid());
          valid.push($("input[name='deal[name]']").valid());
          valid.push($("#hidden-deal-field").valid());
          valid.push($("select[name='deal[deal_category_id]']").valid());

          if (!valid.every(Boolean)) {
            return false
          }

          var valuesToSubmit = $('.deal_form').serialize();
          let type = 'POST';
          let url = '/deals';
          if ($('#deal_uuid').val()){
            type = 'PUT';
            url = '/deals/' + $('#deal_uuid').val();
          }

          $.ajax({
              type: type,
              url: url,
              data: valuesToSubmit,
              dataType: "JSON",
              success: function (data, status, xhr) {
                $('#deal_uuid').val(data.uuid);
                window.Deal.initPreview(data);
            }
          });
        }
        else if (currentStepIndex == 1) {

          valid.push($("#deal_quantity").valid());
          valid.push($("#deal_frequency_type").valid());
          valid.push($("#deal_frequency_interval").valid());
          valid.push($("#deal_frequency_unit").valid());
          valid.push($("#deal_budget").valid());
          window.Deal.initRevenueSlider();

          var valuesToSubmit = $('.deal_form').serialize();

          $.ajax({
              type: 'PUT',
              url: '/deals/' + $('#deal_uuid').val(),
              data: valuesToSubmit,
              dataType: "JSON",
              success: function (data, status, xhr) {
                window.Deal.initPreview(data);
            }
          });
        }
        else if (currentStepIndex == 2) {
          valid.push($('#deal_stage_deadline').valid());

          var valuesToSubmit = $('.deal_form').serialize();

          $.ajax({
              type: 'PUT',
              url: '/deals/' + $('#deal_uuid').val(),
              data: valuesToSubmit,
              dataType: "JSON",
              success: function (data, status, xhr) {
                window.Deal.initPreview(data);
            }
          });
        }
        else if (currentStepIndex == 3) {

          var valuesToSubmit = $('.deal_form').serialize();

          $.ajax({
              type: 'PUT',
              url: '/deals/' + $('#deal_uuid').val(),
              data: valuesToSubmit,
              dataType: "JSON",
              success: function (data, status, xhr) {
                window.Deal.initPreview(data);
              }
          });
        }
      return !valid.includes(false);
    });

    if (document.querySelector('#step-0') != null){
      step0ResizeObserver.observe(document.querySelector('#step-0'));
      step3ResizeObserver.observe(document.querySelector('#step-3'));
      step2ResizeObserver.observe(document.querySelector('#step-2'));
      step4ResizeObserver.observe(document.querySelector('.tab-content'));

    }
  },
  initPreview(data){

    uuid = data.uuid;
    let unit = $('#deal_quantity_unit').val();
    $('#d_name').text(data.name);
    $('#d_description').html(data.description);
    $('#d_location').text(data.location);
    $('#d_buyer_location').text(data.buyer_location);
    $('#d_turnover').text( data.turnover );
    $('#d_rating').text( data.rating);
    function formatNumberWithCommas(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    var quantity = data.quantity;
    var formattedQuantity = formatNumberWithCommas(quantity);
    $('#d_quantity').text(`${formattedQuantity} ${unit}`);

    function number_with_precision(priceString, precision) {
        var numericValue = parseFloat(priceString.replace('€', '').trim());
        if (priceString != 'Not Specified' && typeof numericValue === 'number') {
            var roundedNumber = numericValue.toFixed(precision);
            var formattedNumber = roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return ('€' + formattedNumber);
        } else {
            return (priceString);
        }
    }

    var formated_price = number_with_precision(data.target_price, 2);
    var formated_budget = number_with_precision(data.budget, 2);
    $('#d_budget').text(formated_budget);
    $('#d_target_price').text(formated_price);
    $('#d_frequency').text( data.frequency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    let incumbent_supplier = document.getElementsByName('deal[incumbent_supplier]')[0].value;
    $('#d_incumbent_supplier').text(incumbent_supplier);
    let stage_deadline = document.getElementsByName('deal[stage_deadline]')[0].value;
    $('#d_deadline').text(this.formatDateString(stage_deadline));

    var certificatesString = data.certificates;
    var certificatesArray = certificatesString.split(',');
    var certificateElement = $('#d_certificate');
    certificateElement.empty();
    certificatesArray.forEach(function(certificate) {
      certificate = certificate.trim();
      certificateElement.append('<li>' + certificate + '</li>');
    });
    certificateElement.css('list-style-type', 'disc');

    let category = document.getElementsByName('deal[deal_category_id]')[0];
    let deal_category = category.options[category.selectedIndex].text;
    $('#d_category').text(deal_category);

    let attachments = ""
    data.pdf.forEach(function(value, index) {
      attachments +=
      `<div class="row mb-1">
        <div class="col-auto align-self-center">
          <i class="fas fa-file-pdf">
          </i></div>
        <div class="col align-self-center">
          <p class="my-auto">
            ${data.attachments_name[index]}
          </p>
        </div>
        <div class="col-4 text-end p-0">
          <a class="btn btn-primary btn-sm mx-3" target="_blank" href="${value}">
            <i class="fas fa-cloud-download-alt mr-2 text-white" style="margin-right: 8px;">
            </i>View
          </a></div>
      </div>`
    });
    $('#d_attachment').empty()
    $('#d_attachment').append(attachments)

    let images = ""
    data.images.forEach(function(value, index) {
      images +=
      `<div class="col-sm-6 col-md-4 col-lg-3 item">
        <a href="${value}" target="_blank"><img class="img-fluid" src="${value}" /></a>
      </div>`
    });
    $('#d_images').empty()
    $('#d_images').append(images)
  },
  changeDealStatus(){
    $('.submit-deal-btn').click(function() {
      $.ajax({
          type: "PUT",
          url: `/deals/${uuid}/live`,
          data: { state: true },
      });
    })
  },
  addCustomCSS(){
    $(".sw-btn-next").addClass("blue-sm-btn px-3");
    $(".sw-btn-prev").addClass("blue-sm-btn px-3");
  },
  parseRevenueSliderDisplayValue(value) {
    switch (value){
      case 0 :
        return "€0";
        break;
      case 1 :
        return "€100,000";
        break;
      case 2 :
        return "€500,000";
        break;
      case 3 :
        return "€1M";
        break;
      case 4 :
        return "€5M";
        break;
      case 5 :
        return "€10M";
        break;
      case 6 :
        return "€20M";
        break;
      case 7 :
        return "€50M";
        break;
      case 8 :
        return "€100M";
        break;
      case 9 :
        return "€500M";
        break;
      case 10 :
        return "€500M+";
        break;
      default:
        return "Not Specified";
    }
  },

  parseRevenueSliderValue(value) {
    switch (value){
      case 0 :
        return 0;
        break;
      case 1 :
        return 100000;
        break;
      case 2 :
        return 500000;
        break;
      case 3 :
        return 1000000;
        break;
      case 4 :
        return 5000000;
        break;
      case 5 :
        return 10000000;
        break;
      case 6 :
        return 20000000;
        break;
      case 7 :
        return 50000000;
        break;
      case 8 :
        return 100000000;
        break;
      case 9 :
        return 500000000;
        break;
      case 10 :
        return -1;
        break;
      default:
        return 0;
    }
  },

  parseRevenueValueToSliderValue(value) {
    switch (parseInt(value)) {
      case 0 :
        return 0;
        break;
      case 100000 :
        return 1;
        break;
      case 500000 :
        return 2;
        break;
      case 1000000 :
        return 3;
        break;
      case 5000000 :
        return 4;
        break;
      case 10000000 :
        return 5;
        break;
      case 20000000 :
        return 6;
        break;
      case 50000000 :
        return 7;
        break;
      case 100000000 :
        return 8;
        break;
      case 500000000 :
        return 8;
        break;
      case -1 :
        return 10;
    }
  },

  formatDateString(dateString) {
    let date = new Date(dateString)
    let day = String(date.getDate()).padStart(2, '0');
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let year = date.getFullYear();

      return `${day}/${month}/${year}`;
  },

  validateForm() {
   $.validator.addClassRules("hidden-deal-field-class", { checkPotentailInfo: true });
    $.validator.addMethod ("dealFrequencyRecurring", function (value, element) {
      //if frequency_type is once then we can return valid
      if ($("#frequency_type").val() == "once" || $("#frequency_type").val() == "for_discussion" ) {
        return true;
      }

      if (value == null || !Number.isInteger(parseInt(value)) || parseInt(value) < 1) {
        return false;
      }

      return true;
    }, "Please enter a numeric value greater than zero.");

    $.validator.addMethod ("dealStageDeadline", function (value, element) {

      let deadline = new Date($('#deal_stage_deadline').val());
      let diffDays = Math.ceil((deadline - new Date()) / (1000 * 60 * 60 * 24));

      if (diffDays >= 2 ) {
        return true;
      } else {
        return false;
      }

    }, "You must select a Deal Stage Deadline more than 48 hours from now");

    $.validator.addMethod("checkPotentailInfo", function(value) {
      var potentailInfo = false
      $.ajax({ url: `/check_potential_info`,
        data: { 'deal[description]': value},
        dataType: 'json',
        async: false,
        success: function(data) {
          potentailInfo = data;
        }
      });
      return !potentailInfo;
    }, 'Sorry, our system has identified contact details in your description, which are not allowed here, please edit your description to proceed - thank you');

    $(".deal_form").validate({
      onkeyup: false,
      onblur: true,
      rules: {
        "deal[team_id]": {
          required: true
        },
        "deal[name]": {
          required: true,
          maxlength: 22
        },
        "deal[deal_category_id]": {
          required: true
        },
        "deal[deal_status]": {
          required: true
        },
        "deal[deal_stage]": {
          required: true
        },
        "deal[stage_deadline]": {
          dealStageDeadline: true
        },
        "deal[description]": {
          required: true
        },
        "deal[quantity]": {
          required: true,
          min: 1
        },
        "deal[frequency_type]": {
          required: true
        },
        "deal[frequency_interval]": {
          dealFrequencyRecurring: true
        }
      },
      messages: {
        'deal[deal_status]': 'Please select a Deal Status.'
      },
      errorPlacement: function (error, element) {
        if((element.hasClass('multi-select2') || element.hasClass('country-select2')) && element.next('.select2-container').length) {
          error.insertAfter(element.next('.select2-container'));
        }
        else {error.insertAfter(element)}
      }
    });
  },

  handleRemoveAttachment(e) {
    const attachmentId = e.target.dataset.attachmentId
    $(`#deal-attachments :input[value="${attachmentId}"]`).remove()
    $(e.target).parents('.dz-preview').remove()
  },

  handleOnClickRemoveAttachment() {
    $(".remove-attachment").on('click', this.handleRemoveAttachment.bind(this))
  },

  handleRating(){
    $('.rating-box').on('click', function(){
      $('#deal_supplier_min_rating').val(this.value)
      $('#supplier_min_ratings_' + this.value).prop("checked", true)
      for(var i = this.value; i <= 5; i++) {
        $('#supplier_min_ratings_' + i).prop("checked", true)
      }
      for(var i = 0; i < this.value; i++) {
        $('#supplier_min_ratings_' + i).prop("checked", false)
      }
    })
  },

  toggleRating(){
    var hideElements = function(){
      $('#rating-list li').each(function(i){
        if(i > 2){
          $(this).slideToggle(200);
        }
      });
    };
  },

  ratingCheckboxOnLoad() {
    let minRating = $('#deal_supplier_min_rating').val();
    $(`#supplier_min_ratings_${minRating}`).click()
  },

  handleCompanyAgeRange() {
    $('#company_age_range').change(() => {
      let ageRange = $('#company_age_range').val().split('-')
      if (ageRange != ['']) {
        if (ageRange[0]) {
          $('#deal_supplier_min_company_age').val(ageRange[0].trim())
        }

        if(ageRange[1]) {
          $('#deal_supplier_max_company_age').val(ageRange[1].trim())
        } else {
          $('#deal_supplier_max_company_age').val('')
        }
      } else {
        $('#deal_supplier_min_company_age').val('')
        $('#deal_supplier_max_company_age').val('')
      }
    })
  },

  handleSubmitDealTags() {
    var submitDealTagsBtn = $("#submit-deal-tags-btn");
    submitDealTagsBtn.on("click", function() {
      submitDealTagsBtn.prop('disabled', true).text('Processing...');
      let dealUuid = submitDealTagsBtn.data("uuid");

      let tags = [];
      if ($('#tags').val().length > 0) {
        tags = JSON.parse($('#tags').val()).map(x => x.value);
      }
      $.ajax({
        type: "POST",
        url: '/save-tags',
        data: {
          deal_uuid: dealUuid,
          tags: tags
        }
      });
    });
  }
};
